import { useCool, config } from "/@/cool";
import storage from "/@/cool/utils/storage";
import { useBase } from "/$/base";

const getRequest = (value: any) => {
	const url = window.location.href;
	const p = url.split("?")[1];
	const params = new URLSearchParams(p);
	return params.get(value);
};

export const getOem = async () => {
	const { oem } = useBase();
	const { service } = useCool();
	//获取当前的域名
	const domain = window.location.host;
	//判断是否有oemId
	let oemId = getRequest("oem_id");
	if (!oemId) {
		oemId = storage.get("oem_id") || "";
	}
	//保存起来
	if (oemId) {
		storage.set("oem_id", oemId);
	}

	const data = await service.oem.info({
		domain: domain,
		oem_id: oemId,
		baseUrl: config.baseUrl,
		forceBaseUrl: true
	});
	oem.set(data);
};

export const getOemSetting = (key: string) => {
	return useBase().oem.getValue(key);
};
